import addCategory from './addCategory.svg';
import alertPickup from './alertPickup.svg';
import americanexpress from './americanexpress.svg';
import applePay from './apple.svg';
import appleStore from './appleStore.svg';
import BG from './bg-2.svg';
import cardOnDelivery from './cardOnDelivery.png';
import cash from './cash.svg';
import totalMessages from './chatting.svg';
import createTable from './createTable.svg';
import defaultLogo from './defaultLogo.svg';
import defaultUpload from './defaultUpload.svg';
import deleteIcon from './delete-menu.svg';
import delivery from './delivery.svg';
import dineIn from './dineIn.svg';
import driveThrough from './driveThrough.svg';
import editPencil from './edit-pencil.svg';
import emptyState from './empty_state.svg';
import emptyIntegration from './emptyIntegration.svg';
import emptyPOS from './emptyPOS.svg';
import ETA from './eta.svg';
import exportIcon from './export.svg';
import fleetManagement from './fleetManagement.svg';
import errorFoodics from './foodicsError.png';
import googleStore from './googleStore.svg';
import googleTranslate from './googleTranslate.png';
import inStorePickup from './inStorePickup.svg';
import foodLabel from './label-food.svg';
import linkMenu from './linksMenuMobile.svg';
import loginSignup from './login-signup.svg';
import logo_ar from './logo_ar.svg';
import logo from './logo.svg';
import mada from './mada.png';
import mainLinkPreview from './main-link-preview.png';
import Map from './map-icon.svg';
import mastercard from './mastercard.svg';
import menu from './menu.svg';
import placeholderMenuItem from './menuItemPlaceholder.svg';
import multipleSelect from './multiple_select.svg';
import noCustomers from './no-customers.svg';
import noLinks from './no-links.svg';
import noMenu from './no-menu.svg';
import notPaidOrder from './no-order-2.svg';
import noOrdersPaid from './no-order-paid.svg';
import noStore from './no-store.svg';
import noFleetManagement from './noFleetManagement.svg';
import notFoundUser from './notfound-user.svg';
import onDemand from './onDemand.svg';
import payAtStore from './payatstore.svg';
import pickup from './pickup.svg';
import placeholder from './placeholder.svg';
import placeHolderStore from './placeholderStore.svg';
import noPosLogin from './pos-login.svg';
import qrLogo from './qr_logo.png';
import qr from './qr.svg';
import ratingAngry from './ratingAngry.svg';
import ratingGood from './ratingGood.svg';
import ratingHappy from './ratingHappy.svg';
import ratingNeutral from './ratingNeutral.svg';
import ratingSad from './ratingSad.svg';
import reservation from './reservation.png';
import selfService from './selfService.svg';
import totalOrders from './sent.svg';
import settings from './settings.svg';
import singleSelect from './single_select.svg';
import socialMedia from './socialMedia.png';
import stop from './stop.svg';
import successFoodics from './successFoodics.svg';
import table from './table.svg';
import testOrder from './testorder.svg';
import unit from './unit.svg';
import userAvatar from './user-avatar.svg';
import visa from './visa.svg';
import waiterService from './waiterService.svg';

export const Images = {
  foodLabel,
  Map,
  BG,
  menu,
  logo,
  logo_ar,
  multipleSelect,
  singleSelect,
  qrLogo,
  notFoundUser,
  userAvatar,
  dineIn,
  pickup,
  delivery,
  applePay,
  visa,
  mada,
  mastercard,
  testOrder,
  payAtStore,
  cash,
  americanexpress,
  noCustomers,
  notPaidOrder,
  noMenu,
  noLinks,
  noStore,
  noPosLogin,
  noOrdersPaid,
  loginSignup,
  ETA,
  stop,
  fleetManagement,
  noFleetManagement,
  inStorePickup,
  driveThrough,
  editPencil,
  deleteIcon,
  settings,
  addCategory,
  totalMessages,
  totalOrders,
  placeholder,
  placeholderMenuItem,
  emptyPOS,
  appleStore,
  googleStore,
  successFoodics,
  errorFoodics,
  alertPickup,
  export: exportIcon,
  emptyIntegration,
  placeHolderStore,
  onDemand,
  unit,
  table,
  createTable,
  linkMenu,
  qr,
  defaultLogo,
  defaultUpload,
  selfService,
  waiterService,
  cardOnDelivery,
  reservation,
  socialMedia,
  emptyState,
  ratingAngry,
  ratingGood,
  ratingHappy,
  ratingNeutral,
  ratingSad,
  googleTranslate,
  mainLinkPreview,
};
